<template>
  <div :class="`d-flex ${flexDirection}`">
    <slot name="prepend" />
    <slot
      name="selected-label"
      :labelList="labelList"
    >
      <div :class="`d-flex flex-wrap flex-${chipsDirection}`">
        <label-ref
          v-for="(id,index) in labelList"
          :close="!readonly"
          :id="id"
          :key="index"
          :dense="dense"
          @click:close="removeLabel(id)"
          class="mr-2 my-1"
          v-bind:[isDraggable]="'draggable'"
        />
        <span
          class="ml-4"
          v-if="readonly && !labelList.length"
        >{{$t('t.None')}}</span>
        <picker
          v-if="!readonly && !maxLength && showPicker  && (multiple || (!multiple && !labelList.length))"
          class="ml-2 d-flex width-picker"
          document-types="labels"
          :attach="attach"
          :excluded-items="excludedItems"
          :label="$t('t.AddLabel')"
          :rules="rules"
          :selected-id.sync="newSelectedLabelId"
          :dense="dense"
          hide-details
          reset-on-select
          @update:search-input="queryForAdd($event)"
        >
          <template v-slot:append="">
            <v-btn
              v-if="canAdd"
              icon
              small
              :disabled="isAdding || isCheckingForAdd"
              @click="addLabel"
            >
              <icon-tooltip
                icon-name="i.AddTag"
                :tooltip-text="$t('t.AddLabel')"
                color="primary"
              />
            </v-btn>
          </template>
        </picker>
      </div>
    </slot>

  </div>
</template>

<script>
export default {
  components: {
    IconTooltip: () => import('@/components/icon-tooltip'),
    LabelRef: () => import('@/components/documents-ref/label-ref'),
    Picker: () => import('@/components/picker')
  },
  data: function () {
    return {
      newSelectedLabelId: null,
      searchInput: null,
      canAdd: false,
      isCheckingForAdd: false,
      isAdding: false,
      httpForCanAdd: this.$http().debounced(),
      multiple: Array.isArray(this.selection)
    }
  },
  watch: {
    newSelectedLabelId: {
      immediate: true,
      handler (n, o) {
        if (!o && n) {
          this.labelList = this.multiple ? [...this.labelList, n] : [n]
          this.$nextTick(() => { this.newSelectedLabelId = null })
        }
      }
    }
  },
  computed: {
    excludedItems () {
      return this.labelList
    },
    maxLength () {
      return this.labelList?.length >= 10
    },
    labelList: {
      get () {
        return this.lodash.cloneDeep(Array.isArray(this.selection) ? this.selection : (this.selection ? [this.selection] : []))
      },
      set (v) {
        this.$emit('update:selection', this.multiple ? v : (v ? v[0] : v))
      }
    },
    isDraggable () {
      return this.isChipDraggable ? 'draggable' : null
    },
    _prependProps () {
      const _ = Object.assign({
        left: false,
        top: true,
        right: false,
        bottom: false
      }, this.prependProps)

      return _
    },
    flexDirection () {
      const d = this._prependProps.left || this._prependProps.right
        ? 'row'
        : 'column'

      const r = this._prependProps.right || this._prependProps.bottom
        ? '-reverse'
        : ''

      return `flex-${d}${r}`
    }
  },
  methods: {
    async queryForAdd (searchInput) {
      this.searchInput = searchInput
      this.canAdd = false
      if (this.searchInput && this.searchInput.length <= 20) {
        this.isCheckingForAdd = true
        try {
          await this.httpForCanAdd.post('/core/v6/labels/check', { name: this.searchInput })
        } catch (err) {
          if (err.response?.status !== 400 && err !== 'Debounced') {
            throw err
          }
          return
        } finally {
          this.isCheckingForAdd = false
        }

        this.canAdd = !!this.searchInput
      } else {
        this.canAdd = false
      }
    },
    async addLabel () {
      if (this.searchInput && this.canAdd) {
        this.isAdding = true

        try {
          const r = await this.$http().post('/core/v6/labels', { name: this.searchInput })
          this.newSelectedLabelId = r?.data?.id
          this.canAdd = false
          this.searchInput = ''
        } finally {
          this.isAdding = false
        }
      }
    },
    updateList () {
      this.$emit('update:selection', this.multiple ? this.labelList : (this.labelList ? this.labelList[0] : this.labelList))
    },
    removeLabel (labelId) {
      const list = this.labelList
      const index = list.findIndex(id => id === labelId)
      if (index > -1) {
        list.splice(index, 1)
        this.labelList = list
      }
    }
  },
  props: {
    attach: {
      type: Boolean,
      default: true
    },
    chipsDirection: {
      type: String,
      default: 'row'
    },
    selection: {
      type: [Array, Object]
    },
    label: String,
    prependProps: Object,
    readonly: {
      type: Boolean,
      default: false
    },
    isChipDraggable: {
      type: Boolean,
      default: false
    },
    showPicker: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    rules: Array
  }
}
</script>

<style lang="stylus" scoped>
.label-picker
  flex-wrap wrap

.width-picker
  width 150px
</style>
